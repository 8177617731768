<template>
  <section class="logo-wrapper">
    <div class="content-wrapper">
      <div class="logo">
        <router-link to="/">
          <Vue3Lottie
            v-show="animationStarted"
            :animationData="LogoJSON"
            :autoPlay="true"
            @onAnimationLoaded="handleAnimationStart"
          ></Vue3Lottie>
          <img
            v-if="!animationStarted"
            src="../assets/logo-3.svg"
            alt="Fallback Logo"
          />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import LogoJSON from "../assets/logo.json";
import { ref } from "vue";

export default {
  name: "LogoComponent",
  components: {
    Vue3Lottie,
  },
  setup() {
    const animationStarted = ref(false);

    function handleAnimationStart() {
      animationStarted.value = true;
    }

    return {
      LogoJSON,
      animationStarted,
      handleAnimationStart,
    };
  },
};
</script>

<style scoped lang="scss">
section.logo-wrapper {
  text-align: left;
  align-self: flex-start;
  // font-size: 18px;
  .content-wrapper {
    padding-bottom: 0;
    @include mobile {
      padding-top: 15%;
    }
    .logo {
      .lottie-animation-container {
        width: 12em !important;
        height: auto;

        @include tablet {
          width: 10em !important;
        }

        @include large-mobile {
          width: 25vw !important;
        }

        @include mobile {
          width: 40vw !important;
        }
      }
      img {
        opacity: 0;
        animation: fadeIn 1s ease-in-out forwards;
        animation-delay: 300ms;
      }
      svg,
      img {
        width: 12vw;
        height: auto;

        @include tablet {
          width: 15vw;
        }

        @include large-mobile {
          width: 25vw;
        }

        @include mobile {
          width: 40vw;
        }

        .st0 {
          fill: #ffffff;
        }
        .st1 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 6;
          stroke-miterlimit: 10;
        }

        #logo-outline {
          stroke-dasharray: 673;
          stroke-dashoffset: 673;
          animation: drawBoxOutline 1s ease forwards;
        }

        @keyframes drawBoxOutline {
          to {
            stroke-dashoffset: 0;
          }
        }
      }
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
