<template>
  <div class="title-bar">
    <h3 v-if="data">{{ data.sectionHeader }}</h3>
    <h3 v-else>{{ titleText }}</h3>
    <div class="bar" ref="target" :class="visibleClass"></div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

export default {
  name: "TitleBar",
  props: {
    data: Object,
    titleText: String,
  },
  setup() {
    const target = ref(null);
    const isVisible = ref(false);

    const visibleClass = computed(() => {
      return isVisible.value ? "animate-bar" : "";
    });

    useIntersectionObserver(
      target,
      ([{ isIntersecting }], observerElement) => {
        isVisible.value = isIntersecting;
        if (isIntersecting) {
          observerElement.unobserve(target.value);
        }
      },
      {
        threshold: 0.1,
      }
    );

    return {
      isVisible,
      target,
      visibleClass,
    };
  },
};
</script>

<style lang="scss">
.title-bar {
  @include flex-center-row;
  position: relative;
  width: 100%;
  text-align: left;
  column-gap: 1em;

  h3 {
    flex: 1 0 auto;
  }
  .bar {
    height: 8px;
    width: 100%;
    background-color: $white;
    transform-origin: left;
    transform: scaleX(0);

    &.footer-bar-left {
      transform-origin: right;
      &.animate-bar {
        animation: growBar 1s ease-in-out forwards;
      }
    }

    &.animate-bar {
      animation: growBar 1s ease-in-out forwards;
    }
  }
}

@keyframes growBar {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
</style>
