<template>
  <section class="hero">
    <div class="content-wrapper">
      <div class="wrapper">
        <div class="title-wrapper">
          <div v-for="(wordGroup, index) in wordGroups" :key="index">
            <h1
              v-for="(word, wordIndex) in wordGroup"
              :key="wordIndex"
              class="animated-text"
              :style="{ animationDelay: `${index * 0.5 + wordIndex * 0.5}s` }"
            >
              {{ word }}
            </h1>
          </div>
        </div>
        <div v-html="convertedDescription"></div>
      </div>
    </div>
  </section>
</template>

<script>
import * as marked from "marked";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { ref, computed, onMounted } from "vue";

export default {
  name: "HeroComponent",
  props: {
    data: Object,
  },
  setup(props) {
    const wordGroups = ref([]);

    const renderedHtml = computed(() => {
      if (props.data.header.json) {
        return documentToHtmlString(props.data.header.json);
      }
      return "";
    });

    function extractText(html) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      const headers = [];
      const h1Elements = tempDiv.querySelectorAll("h1");

      h1Elements.forEach((h1) => {
        headers.push(h1.textContent || h1.innerText || "");
      });

      return headers; // Returns an array of strings, each representing an <h1> text
    }

    function prepareWords() {
      const headers = extractText(renderedHtml.value);
      wordGroups.value = headers.map((header) => header.split(" ")); // Split each header into words
    }

    const convertedDescription = computed(() => {
      if (props.data && props.data.description) {
        return marked.parse(props.data.description);
      }
      return "";
    });

    onMounted(() => {
      prepareWords();
    });

    return {
      wordGroups,
      convertedDescription,
    };
  },
};
</script>

<style scoped lang="scss">
section.hero {
  text-align: left;
  align-self: flex-start;

  .content-wrapper {
    @include mobile {
      padding-top: 7.5%;
    }
  }

  .logo {
    margin-bottom: 3em;

    @include mobile {
      margin-bottom: 1em;
    }

    img {
      width: 12vw;

      @include tablet {
        width: 15vw;
      }

      @include large-mobile {
        width: 25vw;
      }

      @include mobile {
        width: 40vw;
      }
    }
  }

  .wrapper {
    max-width: 700px;

    .title-wrapper {
      margin-bottom: 1.5em;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animated-text {
  display: inline-block;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  margin-right: 5px;
}
</style>
