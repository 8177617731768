<template>
  <section class="our-team">
    <TitleBar v-if="data" :data="data" />
    <div class="content-wrapper">
      <div class="flex-grid">
        <div
          class="item"
          v-for="member in data.teamMembersCollection.items"
          :key="member.sys.id"
        >
          <router-link
            class="link-wrapper"
            :to="`/member/${nameToSlug(member.name)}`"
          >
            <img class="headshot" :src="member.headshot.url" alt="headshot" />
            <div class="member-bar"></div>
            <h4>{{ member.name }}</h4>
            <h5 class="secondary">{{ member.title }}</h5>
            <div class="bio-link secondary">
              <img src="../assets/read-bio-icon.svg" width="15" />Read bio
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleBar from "./TitleBar.vue";

export default {
  name: "OurTeam",
  components: {
    TitleBar,
  },
  props: {
    data: Object,
  },
  setup() {
    function nameToSlug(name) {
      return name
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    }

    return {
      nameToSlug,
    };
  },
};
</script>

<style scoped lang="scss">
section.our-team {
  width: 100%;
  align-items: center;

  .content-wrapper {
    width: 100%;
    .flex-grid {
      width: 85%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 1em;
      justify-content: center;
      justify-items: center;

      @include tablet {
        width: 100%;
      }

      @include large-mobile {
        grid-template-columns: repeat(2, 1fr);
      }

      .item {
        &:nth-child(1) {
          grid-column: 2 / span 2;
        }
        &:nth-child(2) {
          grid-column: 4 / span 2;
        }
        &:nth-child(3) {
          grid-column: 1 / span 2;
        }
        &:nth-child(4) {
          grid-column: 3 / span 2;
        }
        &:nth-child(5) {
          grid-column: 5 / span 2;
        }

        @include large-mobile {
          grid-column: 1 / span 2 !important;
        }

        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .link-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .headshot {
            margin-bottom: 0;
            height: 10em;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            transition: 0.15s ease-in-out;
          }

          .member-bar {
            height: 5px;
            width: 80%;
            background-color: $white;
            margin-bottom: 1em;

            @include tablet {
              width: 90%;
            }

            @include large-mobile {
              width: 15em;
            }
          }

          h4 {
            margin-bottom: 0.5em;
          }

          h5 {
            margin-bottom: 1em;
          }

          .bio-link {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 85%;

            img {
              margin-bottom: 0;
              margin-right: 0.5em;
              margin-top: 2px;
            }

            color: $secondary;
            text-decoration: none;
            transition: color 0.15s ease;
          }

          &:hover {
            .headshot {
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
            }

            .bio-link {
              color: $white;
            }
          }
        }
      }
    }
  }
}

// section.our-team {
//   width: 100%;
//   align-items: center;

//   .flex-grid {
//     .item {
//       text-align: center;

//       @include large-mobile {
//         flex: 0 0 70%;
//       }

//       .link-wrapper {
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         align-items: center;

//         .headshot {
//           margin-bottom: 0;
//           height: 10em;
//           -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
//           filter: grayscale(100%);
//           transition: 0.15s ease-in-out;
//         }

//         .member-bar {
//           height: 5px;
//           width: 80%;
//           background-color: $white;
//           margin-bottom: 1em;

//           @include large-mobile {
//             width: 15em;
//           }
//         }

//         h4 {
//           margin-bottom: 0.5em;
//         }

//         h5 {
//           margin-bottom: 1em;
//         }

//         .bio-link {
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           font-size: 85%;

//           img {
//             margin-bottom: 0;
//             margin-right: 0.5em;
//             margin-top: 2px;
//           }

//           color: $secondary;
//           text-decoration: none;
//           transition: color 0.15s ease;
//         }

//         &:hover {
//           h4,
//           h5 {
//             transition: none;
//           }
//           .headshot {
//             -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
//             filter: grayscale(0%);
//           }

//           .bio-link {
//             color: $white;
//           }
//         }
//       }
//     }
//   }
// }
</style>
