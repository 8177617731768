<template>
  <section class="what-we-do">
    <TitleBar v-if="data" :data="data" />
    <div class="content-wrapper">
      <div ref="target" :class="`flex-grid ${visibleClass}`">
        <div
          v-for="(service, index) in data.servicesCollection.items"
          :key="service.sys.id"
          class="item"
          :style="{ 'animation-delay': getDelay(index) + 's' }"
        >
          <div class="item-wrapper">
            <div class="image-wrapper">
              <img
                :src="service.icon.url"
                alt="service"
                :style="{
                  height: widthInEms(service.icon.height * 1.2) + 'em',
                }"
              />
            </div>
            <div class="text-wrapper">
              <h4>
                <span v-html="getFirstWord(service.serviceName)"></span>
                <br />
                <span v-html="getSecondWord(service.serviceName)"></span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed } from "vue";
import { useIntersectionObserver } from "@vueuse/core";
import TitleBar from "./TitleBar.vue";

export default {
  name: "WhatWeDo",
  components: {
    TitleBar,
  },
  props: {
    data: Object,
  },
  setup() {
    const target = ref(null);
    const isVisible = ref(false);

    const visibleClass = computed(() => {
      return isVisible.value ? "is-visible" : "";
    });

    useIntersectionObserver(
      target,
      () => {
        isVisible.value = true;
      },
      {
        threshold: 0.1,
      }
    );

    const getDelay = (index) => {
      const delayIncrement = 0.2;
      return index * delayIncrement;
    };

    const getFirstWord = (str) => str.split(" ")[0];
    const getSecondWord = (str) => str.split(" ")[1];
    const widthInEms = (pixels) => pixels / 18;

    return {
      getDelay,
      isVisible,
      target,
      visibleClass,
      getFirstWord,
      getSecondWord,
      widthInEms,
    };
  },
};
</script>

<style scoped lang="scss">
section.what-we-do {
  width: 100%;
  align-items: center;

  @include mobile {
    font-size: 16px;
  }

  .flex-grid {
    width: 85%;
    margin: auto;
    justify-content: space-around;
    column-gap: 0;

    @include large-mobile {
      width: 100%;
    }

    &.is-visible {
      .item {
        animation: fadeIn 0.5s ease-in-out forwards;
      }
    }
    .item {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 15em;
      flex: none;

      transition: opacity 0.15s ease;
      opacity: 0;

      @include large-mobile {
        width: 11em;
        align-items: flex-end;
      }

      @include mobile {
        width: 9em;
        align-items: flex-end;
      }

      .item-wrapper {
        width: 11em;
        @include mobile {
          width: 9em;
        }
      }

      .image-wrapper {
        @include flex-left-row;
        height: 50px;
        margin-bottom: 1em;
        @include mobile {
          margin-bottom: 0.75em;
        }
      }

      img {
        margin-bottom: 0;
      }

      h4 {
        line-height: 1.3em;

        span {
          position: relative;
          &:last-of-type {
            &:after {
              content: "";
              width: 100%;
              height: 2px;
              background-color: $secondary;
              position: absolute;
              left: 0;
              bottom: -0.5em;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
