<template>
  <section class="member-bio">
    <div class="content-wrapper">
      <div class="wrapper">
        <div class="text-wrapper">
          <div class="title-wrapper">
            <h1>{{ member.name }}</h1>
            <h3 class="secondary">{{ member.title }}</h3>
          </div>
          <div v-html="renderedHtml"></div>
        </div>
        <div class="image-wrapper">
          <img :src="member.headshot.url" alt="headshot" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  name: "MemberBio",
  props: {
    member: Object,
  },
  methods: {},
  computed: {
    renderedHtml() {
      if (this.member.biography.json) {
        return documentToHtmlString(this.member.biography.json);
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
section.member-bio {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  // min-height: 70vh;

  .wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 5em;

    @include tablet {
      column-gap: 3em;
    }
    @include large-mobile {
      flex-direction: column;
      align-items: center;
      row-gap: 2em;
    }
    @include mobile {
      flex-direction: column;
      align-items: center;
      row-gap: 2em;
    }

    .title-wrapper {
      margin-bottom: 1em;

      h1 {
        font-size: 2em;
        margin-bottom: 0.2em;
      }
    }

    .image-wrapper {
      position: relative;
      border: 5px solid $white;
      overflow: hidden;
      width: 100%;
      height: 20em;
      display: flex; // Use flex to center the image if needed
      justify-content: center;
      align-items: center;
      flex: 1 0 33%;

      img {
        max-height: 100%; // Ensure the image scales down if it's too tall
        object-fit: cover; // Cover the container without distorting the aspect ratio

        @include large-mobile {
          max-height: 25em;
        }
        @include mobile {
          max-height: 15em;
        }
      }

      @include tablet {
        height: 15em;
      }

      @include large-mobile {
        width: calc(100% - 10px); // Adjust the width as necessary
      }
    }
  }
}
</style>
