<template>
  <HeroComponent v-if="data" :data="data.heroSection" />
  <WhatWeDo v-if="data" :data="data.serviceSection" />
  <OurTeam v-if="data" :data="data.teamSection" />
</template>

<script>
import HeroComponent from "../components/HeroComponent.vue";
import WhatWeDo from "../components/WhatWeDo.vue";
import OurTeam from "../components/OurTeam.vue";

export default {
  name: "App",
  components: {
    HeroComponent,
    WhatWeDo,
    OurTeam,
  },
  props: {
    data: Object,
  },
};
</script>

<style></style>
