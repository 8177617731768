<template>
  <MemberBio v-if="member" :member="member" />
  <section class="other-members">
    <TitleBar v-if="data" titleText="Other Members" />
    <div class="content-wrapper">
      <div class="flex-grid">
        <div
          class="item"
          v-for="otherMember in otherMembers"
          :key="otherMember.sys.id"
        >
          <router-link
            class="link-wrapper"
            :to="`/member/${nameToSlug(otherMember.name)}`"
          >
            <img
              class="headshot"
              :src="otherMember.headshot.url"
              alt="headshot"
            />
            <div class="member-bar"></div>
            <h4>{{ otherMember.name }}</h4>
            <h5 class="secondary">{{ otherMember.title }}</h5>
            <div class="bio-link secondary">
              <img src="../assets/read-bio-icon.svg" width="15" />Read bio
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import MemberBio from "../components/MemberBio.vue";
import TitleBar from "../components/TitleBar.vue";
import { fetchMembers, fetchMemberByName } from "../api/contentful.js";

export default {
  name: "MemberDetails",
  components: {
    MemberBio,
    TitleBar,
  },
  setup() {
    const route = useRoute();
    const member = ref(null);
    const members = ref([]);

    const fetchMembersData = async () => {
      const fetchedMembers = await fetchMembers();
      if (fetchedMembers) {
        members.value = fetchedMembers.items;
      }
    };

    const fetchCurrentMemberData = async () => {
      const memberName = decodeURIComponent(
        route.params.name.replace(/-/g, " ")
      );
      const fetchedMember = await fetchMemberByName(memberName);
      if (fetchedMember) {
        member.value = fetchedMember;
      } else {
        console.error("Failed to fetch member data for:", memberName);
      }
    };

    watch(
      route,
      async () => {
        await fetchMembersData();
        await fetchCurrentMemberData();
      },
      { immediate: true }
    );

    onMounted(async () => {
      await fetchMembersData();
      await fetchCurrentMemberData();
    });

    const otherMembers = computed(() =>
      members.value.filter((m) => m.name !== member.value?.name)
    );

    return {
      member,
      otherMembers,
      nameToSlug: (name) =>
        name
          .toLowerCase()
          .replace(/ /g, "-")
          .replace(/[^\w-]+/g, ""),
    };
  },
};
</script>

<style scoped lang="scss">
section.other-members {
  width: 100%;
  align-items: center;

  .content-wrapper {
    width: 100%;
    .flex-grid {
      width: 100%;
      .item {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 20%;

        @include tablet {
          flex: 0 0 40%;
        }

        .link-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .headshot {
            margin-bottom: 0;
            height: 10em;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            transition: 0.15s ease-in-out;
          }

          .member-bar {
            height: 5px;
            width: 100%;
            background-color: $white;
            margin-bottom: 1em;

            @include large-mobile {
              width: 15em;
            }
          }

          h4 {
            margin-bottom: 0.5em;
          }

          h5 {
            margin-bottom: 1em;
          }

          .bio-link {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 85%;

            img {
              margin-bottom: 0;
              margin-right: 0.5em;
              margin-top: 2px;
            }

            color: $secondary;
            text-decoration: none;
            transition: color 0.15s ease;
          }

          &:hover {
            h4,
            h5 {
              transition: none;
            }
            .headshot {
              -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
              filter: grayscale(0%);
            }

            .bio-link {
              color: $white;
            }
          }
        }
      }
    }
  }
}
</style>
