<template>
  <div class="site-wrapper">
    <LogoComponent v-if="data" :data="data.heroSection.logo" />
    <router-view v-if="data" :data="data"></router-view>
    <FooterComponent v-if="data" :data="data.footer" />
  </div>
  <Vue3Lottie class="dots-left" :animationData="BackgroundJSON" :delay="600" />
  <Vue3Lottie class="dots-right" :animationData="BackgroundJSON" :delay="300" />
</template>

<script>
import LogoComponent from "./components/LogoComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import { Vue3Lottie } from "vue3-lottie";
import BackgroundJSON from "./assets/Grid-qNzDZ.json";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { fetchAllData } from "./api/contentful.js";

export default {
  name: "App",
  components: {
    Vue3Lottie,
    LogoComponent,
    FooterComponent,
  },
  setup() {
    const route = useRoute();

    watch(route, () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });

    const data = ref(null);

    const fetchData = async () => {
      data.value = await fetchAllData();
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      data,
      BackgroundJSON,
    };
  },
};
</script>

<style scoped lang="scss">
.site-wrapper {
  position: relative;
  z-index: 50;
}
.lottie-animation-container {
  position: fixed;
  width: 60vw;
  height: auto;
  top: 0%;
  right: 0%;
  opacity: 0.7;
  z-index: 0;
  pointer-events: none;
  shape-rendering: crispEdges;

  @include large-mobile {
    width: 80vw;
  }

  &.dots-left {
    transform: rotate(180deg);
    left: 0%;
    right: auto;
    top: auto;
    bottom: -30%;
  }
}
</style>
