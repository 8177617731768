<template>
  <footer>
    <div class="title-bar">
      <div ref="target" :class="`bar footer-bar-left ${visibleClass}`"></div>
      <h3>
        <span class="secondary" v-html="getFirstWord(data.footerText)"></span>
        <br class="mobile-show" />
        <span v-html="getSecondWord(data.footerText)"></span>
      </h3>
      <div ref="target" :class="`bar ${visibleClass}`"></div>
    </div>
  </footer>
</template>

<script>
import { ref, computed } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

export default {
  name: "FooterComponent",
  props: {
    data: Object,
  },
  setup() {
    const target = ref(null);
    const isVisible = ref(false);

    const visibleClass = computed(() => {
      return isVisible.value ? "animate-bar" : "";
    });

    useIntersectionObserver(
      target,
      ([{ isIntersecting }], observerElement) => {
        isVisible.value = isIntersecting;
        if (isIntersecting) {
          observerElement.unobserve(target.value);
        }
      },
      {
        threshold: 0.1,
      }
    );

    const getFirstWord = (str) => {
      return str.split(" ")[0] + " ";
    };
    const getSecondWord = (str) => {
      const words = str ? str.split(" ") : [];
      if (words.length > 1) {
        return `<a class="footer-link" href="mailto:${words[1]}">${words[1]}</a>`;
      }
      return "";
    };

    return {
      getFirstWord,
      getSecondWord,
      isVisible,
      target,
      visibleClass,
    };
  },
};
</script>

<style scoped lang="scss">
footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  padding-bottom: 100px;

  @include mobile {
    padding-bottom: 50px;
  }

  h3 {
    font-size: 80%;

    @include mobile {
      font-size: 100%;
      text-align: center;
      line-height: 1.5;
    }
  }

  .bar {
    @include mobile {
      display: none;
    }
  }
}

a {
  &:hover {
    color: $secondary;
  }
}
</style>
