// Function to fetch all members with error handling
export const fetchMembers = async () => {
  try {
    const query = `{
      teamMemberCollection(order: orderNumber_ASC) {
        items {
          sys {
            id
          }
          headshot {
            url
            width
            height
          }
          name
          title
          biography {
            json
          }
          orderNumber
        }
      }
    }`;

    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      }
    );

    const data = await response.json();
    return data?.data?.teamMemberCollection || null; // Return the teamMemberCollection or null if not found
  } catch (error) {
    console.error("Error fetching members:", error);
    return null; // Return null in case of error
  }
};

// Function to fetch a member by name with error handling
export const fetchMemberByName = async (memberName) => {
  try {
    const query = `{
      teamMemberCollection(where: {name_contains: "${memberName}"}, limit: 1) {
        items {
          sys {
            id
          }
          headshot {
            url
            width
            height
          }
          name
          title
          biography {
            json
          }
        }
      }
    }`;

    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      }
    );

    const data = await response.json();
    return data?.data?.teamMemberCollection?.items[0] || null; // Return the first item or null if not found
  } catch (error) {
    console.error("Error fetching member by name:", error);
    return null; // Return null in case of error
  }
};

// Function to fetch all data with error handling
export const fetchAllData = async () => {
  try {
    const query = `{
  homepage(id: "113O4itGkCMqU1RuxAOFw6") {
    pageTitle
		heroSection {
      logo {
        url
      }
      header {
        json
      }
      description
    }
    serviceSection {
      sectionHeader
      servicesCollection(order: orderNumber_ASC) {
				items {
          sys {
            id
          }
          icon {
						url
            width
            height
          }
          serviceName
        }
      }
    }
    teamSection {
			sectionHeader
      teamMembersCollection(order: orderNumber_ASC) {
				items {
          sys {
            id
          }
          pageTitle
        	headshot {
						url
            width
            height
          }
          name
          title
          biography {
						json
          }
        }
      }
    }
    footer {
			footerText
    }
  }
}`;

    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      }
    );

    const data = await response.json();
    return data?.data?.homepage || null;
  } catch (error) {
    console.error("Error fetching members:", error);
    return null; // Return null in case of error
  }
};
