import { createRouter, createWebHistory } from "vue-router";
import MemberDetails from "./pages/MemberDetails.vue";
import HomePage from "./pages/HomePage.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/member/:name",
    component: MemberDetails,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(() => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
